/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useTranslation } from "react-i18next";
import { useToast } from "../../contexts/toast";
import { parsedUser } from "../../utils/GetCurrentUser";
import { Container, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import API from "../../api/api";
import RESERVATION_RESTRICTION_FIELDS from "./ReservationRestrictionsFields";
import {
  generateFormikObject,
  GenerateObjectVersion,
} from "../../utils/generate-formik-object";
import { GenericFields } from "../group-reservation/GenericFields";
import ReservationRestrictionsDetailsPicker from "./ReservationRestrictionsDetailsPicker";

const INITIAL_VALUES = {
  Description: "",
  RestrictionType: "A",
  RestrictionScope: "A",
  IdMarketSegment: "",
  StartDate: null,
  EndDate: null,
  Active: "1",
};

const ReservationRestrictionsDetails = ({ show, onHide, action, record }) => {
  const { Token, IdUser } = parsedUser();
  const toast = useToast();
  const { t } = useTranslation();
  const [validationSchema, setValidationSchema] = useState(null);
  const [fields, setFields] = useState(RESERVATION_RESTRICTION_FIELDS);
  const [actionMode, setActionMode] = useState("I");
  // const [selectedItem, setSelectedItem] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [
    showReservationRestrictionSpecial,
    setShowReservationRestrictionSpecial,
  ] = useState(false);
  const requestRestrictions = useRef(null);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ record:", record);
  }, [record]);

  useEffect(() => {
    setActionMode(action);
    if (action === null) {
      formik.resetForm();
    }

    if (action === "I") {
      formik.resetForm();
      const fieldUpdated = fields.map((field) => {
        if (field.FieldName === "RestrictionScope") {
          field.ReadOnly = false;
        }
        return field;
      });
      setFields(fieldUpdated);
      prepareFormikObject(fieldUpdated);
      fillFormikObject(INITIAL_VALUES);
      setActionMode(action);
    }

    if (action === "U") {
      const fieldUpdated = fields.map((field) => {
        // if (field.FieldName === "RestrictionScope") {
        //   return { ...field, ReadOnly: true };
        // }
        return field;
      });
      setFields(fieldUpdated);
      prepareFormikObject(fieldUpdated);
      setActionMode(action);
      setCurrentRecord(record); // For the picker
      fillFormikObject(record);
    }
  }, [action]);

  useEffect(() => {
    if (fields && fields.length > 0) {
      prepareFormikObject(fields);
    }
    return () => {
      formik.resetForm();
    };
  }, []);

  const prepareFormikObject = (fields = []) => {
    const { validationSchema } = generateFormikObject(fields);
    setValidationSchema(validationSchema);
  };

  const handleSubmit = async (values) => {
    let actions = {
      I: "I",
      U: "U",
      D: "I",
    };

    let objSPVersion = GenerateObjectVersion(values, fields);

    if (actionMode === "U") {
      objSPVersion = ` ${objSPVersion}, @GUID='${
        values.GUID || currentRecord.GUID
      }'`;
    }

    const payload = {
      Data: `${objSPVersion}, @Accion='${actions[actionMode]}'`,
    };
    // return
    try {
      let queryParams = `token=${Token}&IdUser=${IdUser}&ActionMode=${actions[actionMode]}`;
      let request = await API.postAction(
        `api/Restrictions/CRUDpmsReservationRestrictions?${queryParams}`,
        payload
      );
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      // console.log("🚀 ~ handleSubmit ~ parsedResults:", parsedResults.JSONData)

      setCurrentRecord(parsedResults.JSONData);

      if (parsedResults.Error) {
        throw new Error(parsedResults.Msg);
      }

      if (actionMode === "I") {
        setActionMode("U");
        if (values?.RestrictionScope === "R") {
          setShowReservationRestrictionSpecial(true);
        }
      } else {
        onHide();
      }
      toast({
        type: "success",
        message: t("Reservation restriction saved successfully"),
        width: "auto",
        height: "auto",
      });
      return;
    } catch (error) {
      if (error instanceof Error) {
        toast({
          type: "error",
          message: error.message,
          width: "auto",
          height: "auto",
        });
        console.error(error.message);
        return;
      }
      toast({
        type: "error",
        message: t("Error saving reservation restriction"),
        width: "auto",
        height: "auto",
      });
      console.error(error);
      return;
    }
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const fillFormikObject = (record) => {
    // if (fields && fields.length > 0) {
    //   fields.forEach((item) => {
    //     formik.setFieldValue(item.FieldName, record[item.FieldName]);
    //   });
    // }
    if (record) {
      formik.setValues({ ...record, Active: record.Active ? "1" : "0" });
    }
  };

  const updateField = (fieldName, fieldValue) => {
    if (
      (fieldName === "StartDate" || fieldName === "EndDate") &&
      (fieldValue !== null || fieldValue !== "")
    ) {
      requestRestrictions?.current?.requestRestrictions();
    }
    if (fieldValue === "Invalid date") {
      fieldValue = null;
    }
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    let fieldsObj = fields;
    switch (formik.values.RestrictionScope) {
      case "A":
        fieldsObj = fieldsObj.map((item) => {
          if (item.FieldName === "IdMarketSegment") {
            item.Visible = false;
            item.Required = false;
            updateField("IdMarketSegment", "");
          }
          return item;
        });
        setShowReservationRestrictionSpecial(false);
        setFields(fieldsObj);
        prepareFormikObject(fieldsObj);
        break;
      case "M":
        fieldsObj = fieldsObj.map((item) => {
          if (item.FieldName === "IdMarketSegment") {
            item.Visible = true;
            item.Required = true;
          }
          return item;
        });
        setShowReservationRestrictionSpecial(false);
        setFields(fieldsObj);
        prepareFormikObject(fieldsObj);
        break;
      case "R":
        fieldsObj = fieldsObj.map((item) => {
          if (item.FieldName === "IdMarketSegment") {
            item.Visible = false;
            item.Required = false;
            updateField("IdMarketSegment", "");
          }
          return item;
        });
        if (actionMode === "U") setShowReservationRestrictionSpecial(true);
        setFields(fieldsObj);
        prepareFormikObject(fieldsObj);
        requestRestrictions?.current?.requestRestrictions();
        break;
      default:
        break;
    }
  }, [formik.values.RestrictionScope]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size={"lg"}
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Reservation Restrictions")}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Container fluid className="overflow-auto">
              <Row className="">
                {/* Field Selector Here */}
                <GenericFields
                  fields={fields.map((field) => {
                    if (field.FieldName === "RestrictionScope") {
                      return { ...field, ReadOnly: actionMode === "U" };
                    }
                    return field;
                  })}
                  errors={formik.errors}
                  values={formik.values}
                  updateFields={updateField}
                />
              </Row>
              <Row>
                {actionMode === "I" &&
                  formik.values.RestrictionScope === "R" && (
                    <div className="alert alert-info" role="alert">
                      {t(
                        "After you select save and continue the restrictions details will be displayed"
                      )}
                    </div>
                  )}
                {showReservationRestrictionSpecial && (
                  <ReservationRestrictionsDetailsPicker
                    DateStart={currentRecord.StartDate}
                    DateEnd={currentRecord.EndDate}
                    GUIDReservationRestriction={currentRecord.GUID}
                    ref={requestRestrictions}
                  />
                )}
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gx-3 align-items-center justify-content-end">
              <button className="btn me-2" type="button" onClick={onHide}>
                {t("Close")}
              </button>
              <button
                className="btn btn-primary me-2"
                type="button"
                onClick={() => [formik.submitForm()]}
              >
                {/* {t("Save")} */}
                {actionMode === "I" ? "Save & Continue" : "Save"}
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ReservationRestrictionsDetails;
